<template>
    <div>
        <Quote title="Bu yerda o'qituvchilar ro'yxatini ko'rishingiz mumkin"/>
        <GoBack next="O'qituvchilar ro'yxati" class="mb-4"/>
        <div ref="top" class="row justify-content-center">
            <div class="col-11 overflow-hidden">

                <b-table-simple hover>
                    <b-thead class="p-2">
                        <b-tr>
                            <b-th class="d-none d-xl-table-cell">
                                <input
                                    v-model="search.id"
                                    @keyup.enter="searchTeacherInfo"
                                    type="number"
                                    placeholder="Id"
                                    style="width: 55px;border-radius: 5px"
                                />
                            </b-th>
                            <b-th>
                                <input
                                    v-model="search.givenName"
                                    @keyup.enter="searchTeacherInfo"
                                    type="text"
                                    placeholder="Ismi"
                                    style="width:12vw;border-radius: 5px"
                                    class="inputs"
                                />
                            </b-th>
                            <b-th class="d-none d-md-table-cell">
                                <input
                                    v-model="search.familyName"
                                    @keyup.enter="searchTeacherInfo"
                                    type="text"
                                    placeholder="Familiyasi"
                                    style="width: 135px;border-radius: 5px"
                                />
                            </b-th>
                            <b-th>
                                <input
                                    v-model="search.email"
                                    @keyup.enter="searchTeacherInfo"
                                    type="email"
                                    placeholder="E-mail"
                                    style="width:20vw;border-radius: 5px"
                                    id="inputs"
                                />
                            </b-th>
                            <b-th class="d-none d-lg-table-cell">
                                <input
                                    v-model="search.phoneNumber"
                                    @keyup.enter="searchTeacherInfo"
                                    type="text"
                                    placeholder="Telefon"
                                    style="width: 145px;border-radius: 5px"
                                />
                            </b-th>
                            <b-th class="d-none d-xl-table-cell">
                                <input
                                    v-model="search.telegramNumber"
                                    @keyup.enter="searchTeacherInfo"
                                    type="text"
                                    placeholder="Telegram"
                                    style="width: 145px;border-radius: 5px"
                                />
                            </b-th>
                            <b-th class="text-end text-md-center d-sm-table-cell" style="width: 100px">
                                <b-button
                                    v-b-tooltip.hover
                                    title="Izlash"
                                    class="border-0 p-0"
                                    style="background-color: #FFFFFF; font-size:1.6vw;"
                                >
                                    <img
                                        @click="searchTeacherInfo"
                                        style=" height: auto; cursor: pointer; font-size:1.6vw;"
                                        src="../assets/search-icon.png"
                                        alt=""
                                    >
                                </b-button>
                                <b-button
                                    v-b-tooltip.hover
                                    title="Tozalash"
                                    class="border-0 p-0 ms-1"
                                    style="background-color: #FFFFFF; font-size:1.7vw;"
                                >
                                    <img
                                        @click="resetTeachersSearchInfo"
                                        style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                        src="../assets/eraser-icon.png"
                                        alt=""
                                    >
                                </b-button>
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <tr
                            class="pl-3 pt-3 pb-3"
                            v-for="user in getTeachersList"
                            :key="user.id"
                        >
                            <td data-label="User Id" class="d-none d-xl-table-cell">
                                <span class="texts">{{ user.id }}</span>
                            </td>
                            <td v-if="user.isTeacher" data-label="First name">
                                <router-link
                                    :to="'/edit-admin-user/' + user.id"
                                    id="linking"
                                >
                                    <span class="texts hover-decoration">{{ user.givenName }}</span>
                                </router-link>

                            </td>
                            <td v-if="user.isTeacher" data-label="Second name" class="d-none d-md-table-cell">
                                <span class="texts">{{ user.familyName }}</span>
                            </td>
                            <td v-if="user.isTeacher" data-label="Email">
                                <span class="texts">{{ user.email }}</span>
                            </td>
                            <td v-if="user.isTeacher" data-label="Phone number" class="d-none d-lg-table-cell">
                                <span class="texts">{{ user.phoneNumber }}</span>
                            </td>
                            <td v-if="user.isTeacher" data-label="Telegram number" class="d-none d-xl-table-cell">
                                <span class="texts">{{ user.telegramNumber }}</span>
                            </td>
                            <td v-if="user.isTeacher" class="text-end text-md-center d-sm-table-cell">
                                <router-link
                                    :to="'/edit-admin-user/' + user.id"

                                >
                                    <img src="../assets/pen.png" alt="">
                                </router-link>
                                <img
                                    @click="showModal(user.id)"
                                    class="ms-3 trash"
                                    src="../assets/trash.png"
                                    alt="trash-icon"
                                >
                            </td>
                        </tr>
                    </b-tbody>
                </b-table-simple>

                <div>
                    <b-modal ref="modal-delete" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Ushbu o'qituvchini rostdan ham o'chirmoqchimisiz?</h3>
                        </div>
                        <b-button
                            class="mt-3"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hideErrorModal"
                        >
                            Yo'q
                        </b-button>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-danger"
                            size="md"
                            @click="deleteUser"
                        >
                            Ha
                        </b-button>
                    </b-modal>
                </div>

                <div>
                    <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Ushbu o'qituvchi o'chirildi</h3>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hideErrorModal"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>

            </div>
            <div class="mt-3">
                <b-pagination
                    v-if="getTeachersTotalCount >= 20"
                    v-model="currentPage"
                    pills
                    align="center"
                    :per-page="20"
                    :total-rows="getTeachersTotalCount"
                    @input="fetchTeachersByPage"
                    :click-handler="fetchTeachersByPage"
                >
                </b-pagination>
            </div>

            <!--            <pre>{{getTeachersList}}</pre>-->
        </div>
    </div>
</template>

<script>

import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "UsersListPage",
    components: {
        Quote,
        GoBack
    },
    data() {
        return {
            userId: null,
            currentPage: 1,
            search: {
                id: null,
                givenName: '',
                familyName: '',
                // isTeacher: false,
                email: '',
                phoneNumber: '',
                telegramNumber: '',
                page: 1
            },
        }
    },
    computed: {
        ...mapGetters(['getTeachersList', 'getTeachersTotalCount', 'getSearchTeachers'])
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchTeachers(this.currentPage)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions(['fetchTeachers', 'deleteOneUser', 'searchTeacher', 'teacherUser']),
        deleteUser() {
            this.deleteOneUser(this.userId)
                .then(() => {
                    this.$refs['modal-delete'].hide()
                    setTimeout(this.hideErrorModal, 3000)
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                    this.fetchTeachers(this.currentPage)
                })
        },
        fetchTeachersByPage() {
            this.$router.push('/teachers-list/page/' + this.currentPage)
            this.$refs.top.scrollIntoView()
        },
        isTeacher(id) {
            this.teacherUser(id)
                .then(() => {
                    this.fetchTeachers(this.currentPage)
                })
        },
        showModal(id) {
            this.userId = id
            this.$refs['modal-delete'].show()
        },
        hideErrorModal() {
            this.$refs['modal-delete'].hide()
            this.$refs['modal-success'].hide()
        },
        searchTeacherInfo() {
            this.show = true

            this.searchTeacher(this.search)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        resetTeachersSearchInfo() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.fetchTeachers(this.currentPage)
        },
    },
    mounted() {
        this.show = true
        this.fetchTeachers(this.currentPage)
        this.show = false
    }
}
</script>

<style scoped>
img {
    width: 20px;
    height: 20px;
}

input {
    border: 1px solid black;
    margin-bottom: 5px;
}

input::placeholder {
    font-size: 12px;
    padding: 0.5rem;
}

.trash {
    cursor: pointer;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

@media (max-width: 768px) {
    .texts {
        font-size: 3vw;
    }

    input {
        font-size: 2.7vw;
        border: 1px solid black;
    }
}

@media (max-width: 500px) {
    .texts {
        word-break: break-all;
        font-size: 12px;
    }

    input {
        font-size: 3.5vw;
        border: 1px solid black;
    }
}
</style>